@import "https://use.fontawesome.com/releases/v5.5.0/css/all.css";
@import "~bootstrap/scss/bootstrap";
/*@import '~quill/dist/quill.bubble.css';*/
@import '~quill/dist/quill.snow.css';

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth !important;
}
html,
body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  width: 100%;
  height: 100%;
  font-family: 'Nunito', sans-serif;
  overflow-x: hidden;
}

body{
  background: #233668;
}

.black {
  background: #000000 !important;
}

img{
  width: 100%;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900;
}
.text{
  &-right{
    text-align: right !important;
  }
}
.page-title{
  font-weight: 900;
  color: #fff;
  margin-bottom: 30px;
}

.media-body h1 {
  font-weight: 300;
  margin-bottom: 20px;
}

.media-body h1 strong {
  font-weight: 600;
}

.media-body p {
  margin-bottom: 10px;
  font-weight: 300;
}

.media-body p strong {
  margin-bottom: 10px;
  font-weight: 600;
}

.px-logo {
  display: block;
  clear: both;
  margin: 0 auto 20px;
  width: 220px;
}

.px-logo a img {
  width: 100%;
}

.displayTable {
  display: flex;
  padding-top: 1rem;
  justify-content: space-evenly;
  width: 100%;
  height: 93%;
  background: #233668;

  .text {
    color: #ffc85b;
    text-align: center;
    padding: 120px 30px;

    h3 {
      font-size: 18px;
      text-align: center;
      color: white;
    }
  }
}
.displayTable:before{
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  left: 0;
  top: 55px;
  // background: url("assets/img/bg-1.png") no-repeat bottom center;
  background-size: cover;
  opacity: .9;
}

.displayTableCell {
  display: flex;
  flex-direction: column;
  padding: 30px;
  flex: 0 0 40%;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}

h3 {
  text-align: center;
  font-size: 24px;
  margin: 0 0 20px;
}

h5 {
  text-align: center;
  font-size: 20px;
  margin: 0 0 20px;
}

.authBlock {
  margin: 0 auto;
  max-width: 350px;
  overflow: hidden;
  color: #fff;
}

label {
  display: block;
  font-size: 13px;
  padding-bottom: 5px;
  font-weight: 600;
}

.formGroup {
  margin-bottom: 20px;
  float: left;
  width: 100%;
}

.formControl {
  width: 100%;
  display: block;
  padding: 15px 15px 14px;
  border: 2px solid #e7e7e7;
  outline: none;
  font-size: 15px;
  color: #444444;
  background: #fcfcfc;
}

.formControl:focus {
  border: 2px solid #d3d3d6;
}

input::-webkit-input-placeholder {
  color: #bbbbbb;
}

input::-moz-placeholder {
  color: #bbbbbb;
}

input:-ms-input-placeholder {
  color: #bbbbbb;
}

input:-moz-placeholder {
  color: #bbbbbb;
}

.displayTable .btn {
  width: 100%;
  border: none;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 0;
  background: #dc3545;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  border-radius: 10px;
}

.displayTable .btn:hover {
  opacity: 0.88;
}

.displayTable .btnSecondary {
  background: #eeeeee;
  color: #404040;
}

.displayTable .googleBtn {
  background: #77aeda;
}

.displayTable .facebookBtn {
  background: #77aeda;
}

.or {
  text-align: center;
  display: block;
  position: relative;
  margin: 5px 0 0;
}

.orInner {
  background: #233668;
  color: #ffc85b;
  display: inline-block;
  z-index: 4;
  position: relative;
  padding: 0 12px;
}

.or:before {
  position: absolute;
  content: "";
  left: 0;
  top: 11px;
  width: 100%;
  height: 1px;
  background: #ffc85b;
}

.halfWidth {
  width: 48.5%;
}

.left {
  float: left;
}

.right {
  float: right;
}

.forgotPassword {
  text-align: center;
  margin: -12px 0 15px 0;
  float: left;
  width: 100%;
}

.forgotPassword span {
  color: #77aeda;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  display: inline-block;
  padding-top: 20px;
}

.redirectToLogin {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  display: block;
  color: rgba(255, 255, 255, 0.6);
}

.redirectToLogin .redirect {
  cursor: pointer;
  color: #ffffff;
  font-weight: 600;
}

/* * Sidebar */
.sidebar {
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 95px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar{
  padding: 0;
  background: rgba(0, 0, 0, .3);
  .nav{
    margin-top: 25px;
    &-item{
      &.active{
        .nav-link{
          color: #ff634e !important;
        }
      }
      &:hover{
        .nav-link{
          color: #fdb0a6;
        }
      }
    }
    &-link {
      font-weight: 600;
      color: #cdd4e6;
      font-size: 1.3rem;
      padding: 5px 30px;
      .feather {
        margin-right: 4px;
        color: #999;
      }
      &__icon {
        width: 30px;
        margin-right: 10px;
        margin-top: -5px;
      }
    }
  }
  &-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
  }
  .nav-link.active,
  a:hover,
  a:not([href]):not([tabindex]):focus,
  a:not([href]):not([tabindex]):hover {
    color: #fdb0a6;
  }
}

.nav-link {
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #dde0e2;
  cursor: pointer;
}

/* * Content */
[role="main"] {
  padding-top: 48px;
}

.dasboard-text {
  border-left: 1px solid rgb(255, 255, 255, 0.3);
  color: rgb(255, 255, 255, 0.5);
  display: inline-block;
  padding: 0 0 0 14px;
  font-size: 15px;
  margin-left: 15px;
  position: relative;
  top: -1px;
}

/* * Navbar */
.navbar-brand {
  margin: 0 !important;
  padding: 10px 0;
  background: transparent !important;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control{
  font-size: 14px;
  &-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    &:focus {
      border-color: transparent;
      box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
    }
  }
  &:focus {
    border-color: #00bcd4;
    box-shadow: none;
  }
}


.bg-dark {
  background-color: #233668 !important;
}

.gap-right {
  margin-right: 10px;
}

i {
  width: 22px;
  text-align: center;
  margin-right: 5px;
}

.inner-adjust {
  padding: 0 20px;
}

.action-block {
  cursor: pointer;
}

.action-block .fa-edit:hover {
  color: #009688;
}

.action-block .fa-trash-alt:hover {
  color: #e91e63;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}

/* Pagination */
body pagination-template {
  padding: 0;
  margin: 8px 0 0;
  float: left;
  width: 100%;
  text-align: right;
}

body .ngx-pagination li:last-child {
  margin: 0;
}

body .ngx-pagination .current {
  background: #055af9;
}

.ngx-pagination a:hover,
.ngx-pagination button:hover {
  text-decoration: none;
}

/* Error */
.error {
  color: red;
  margin-top: 5px;
}

input.ng-invalid.ng-touched {
  border: 1px solid red;
}

.btn-success.disabled,
.btn-success:disabled {
  cursor: not-allowed;
}

/* Nav */
body .navbar-brand {
  background: none;
}

.pt-3,
.py-3 {
  padding-top: 2.4rem !important;
}

.sidebar-sticky {
  padding-top: 1.2rem !important;
}

/* Form */
label {
  font-weight: 500;
}

.form-control {
  padding: 1.375rem 0.75rem;
}

/* Misc */
.no-data img {
  max-width: 420px;
  margin: 20px auto 0;
}

.nodata-msg {
  margin: 25px 0 15px;
  font-size: 28px;
  color: #a9a6c5;
  font-weight: 300;
  letter-spacing: 0.2px;
}

[role="main"] {
  padding-top: 65px;
}

.preloader {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -15px;
}

.custom-text {
  font-size: 15px;
  color: #5f5f5f;
  letter-spacing: 0.2px;
}

.custom-text strong {
  color: #3a3a3a;
}

.mb-3,
.my-3 {
  margin-bottom: 1.4rem !important;
}

.custom-fa-plus {
  margin: 0;
  width: auto;
}

.user-image {
  width: 42px;
  height: 42px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 7px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
}

body .table thead th {
  background: #f3f5ff;
}

.pricing-header {
  padding-bottom: 50px;
}

.userImage {
  max-width: 125px;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
}

.card {
  border: none;
}

.list-group-item {
  padding: 0 1.25rem 15px;
  border: none;
}

.fa-sign-out-alt {
  position: relative;
  top: 1px;
}

.logOutBtn {
  cursor: pointer;
}

.no-access {
  text-align: center;
  font-size: 26px;
  padding: 70px 0;
}

.rounded-circle {
  max-width: 150px;
}
.formControl {
  border: none !important;
  border-radius: 10px;
}
.nav-link {
  padding: initial;
  border: none;
  font-size: 16px;
  &:hover{
    background: transparent !important;
  }
  &.active{
    color: #ffc85b;
  }
}

.view-wrapper{
  height: calc(100vh - 95px);
  padding: 50px 50px;
  color: #fff;
  overflow: auto;
  margin: 0 -25px;

  h5 {
    text-align: left;
  }

  .top {
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    .card {
      position: relative;
      color: #233668;
      padding: 20px;
      height: 100%;

      &__title {
        font-size: 20px;
      }
      &__text {
        font-size: 18px;
      }
      &__button {
        margin-top: auto;
        border: none;
        background: transparent;
        padding: 0;
        text-align: left;
        color: #45b3df;

        img {
          height: 25px;
          width: auto;
        }
      }
      &__lock {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(35, 54, 104, .8);

        img {
          width: 40%;
          height: auto;
        }
      }
    }
  }

  .videos {
    padding-top: 32px;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    &__content {
      div {
        div {
          width: 100%;
          height: auto;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 0.375rem;
          position: relative;

          .play-button {
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 9px;
            left: 9px;
            display: flex;
            background-color: #9c9c9c;
            border-radius: 6px 10px;
            box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.3);
            transition-duration: 0.3s;
            background-image: url("/assets/icons/play-small.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 65%;
            cursor: pointer;
          }
        }
      }
    }
  }

  .content {
    padding-top: 32px;

    &__wrapper {
      padding-bottom: 32px;
      margin-bottom: 32px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
  }

  .explainer {
    padding-top: 32px;

    h5 {
      margin-bottom: 20px;
    }

    &__video {
      width: 70%;
      padding-bottom: 36%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.color-composition {
  color: #ffc85b;
}

.destination{
  .blank{
    padding: 0;
    border-radius: 5px;
    display: inline-block;
    min-width: 35px;
    min-height: 15px;
    border-bottom: solid 1px #5c5f65;
    margin: 0 3px;
    .word{
      margin: 0 0 -1px 0 !important;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.modal-content{
  border-radius: 10px;
}
.gs-player{
  padding: 0 !important;
  svg {
    fill: #eebc58 !important;
  }
  .media {
    &-ui{
      -ms-grid-columns: 100px auto 50px !important;
      grid-template-columns: 0 auto !important;
      gap: 2rem;
    }
    &-status{
      display: flex !important;
      .media-text{
        &.currenttime{
          width: 35px;
        }
        &.duration{
          display: none;
        }
      }
    }
    &-list {
      display: none !important;
    }
    &-title{
      display: none !important;
    }
    &-control{
      .disabled{
        display: none;
      }
    }
  }
}
.d-mobile-only{
  display: none;
}
.navbar-collapse.show {
  border-top: solid 1px #3a4a79;
  margin: 0 -12px;
  padding: 20px 0;
  .nav-item{
    margin-bottom: 10px;
    img {
      height: 24px;
      width: auto;
      margin-right: 15px;
    }
  }
}

@media all and (max-width: 900px) {
  .container-fluid {
    flex-wrap: wrap !important;
  }
  .d-mobile-only{
    display: initial;
  }
  .navbar-collapse.show {
    border-top: solid 1px #3a4a79;
    margin: 0 -12px;
    padding: 20px 0;
    width: 100vw;
    height: 90vh;
    overflow: scroll;
    .nav-item{
      margin-bottom: 10px;
      img {
        height: 24px;
        width: auto;
        margin-right: 15px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  /*.d-mobile-only{
    display: initial;
  }
  .navbar-collapse.show {
    border-top: solid 1px #3a4a79;
    margin: 0 -12px;
    padding: 20px 0;
    .nav-item{
      margin-bottom: 10px;
      img {
        height: 24px;
        width: auto;
        margin-right: 15px;
      }
    }
  }*/
  .sidebar {
    display: none;
  }
  .view-wrapper {
    padding: 30px;
  }
  .tabs__header {
    .btn-wrapper {
      width: 100% !important;
      display: block;
      white-space: nowrap;
      position: relative;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      button{
        background: rgba(188, 243, 255, .1) !important;
        margin-right: 5px !important;
        &.active{
          background: #bcf3ff !important;
        }
      }
    }
  }
  .chapter_large{
    .lesson {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &__img {
        width: 100% !important;
        margin: 0 0 15px 0 !important;
      }
      &__title{
        font-size: 16px !important;
        margin-bottom: 10px !important;
      }
    }
  }
  .lesson__wrapper .img-wrapper {
    img{
      max-height: initial !important;
    }
  }
  .lesson-snippet.visible {
    transform: scale(.6);
    top: 0 !important;
    bottom: initial !important;
  }
  .footer{
    font-size: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lesson__results.results {
    transform: scale(.5);
  }
  .header .brand-logo {
    height: 35px;
    width: auto !important;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .displayTable {
    flex-direction: column;
    justify-content: flex-start;

    .text {
      padding: 30px;
    }
  }
}

//disable zoom
:root {
  touch-action: pan-x pan-y;
  height: 100%
}
html{
  overflow: hidden;
}
body.mob{
  height: 100%;
  overflow: hidden;
  .fullscreen{
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
    }
    /* Optional: show position indicator in red */
    ::-webkit-scrollbar-thumb {
      background: #FF0000;
    }
  }
}
.tooltip-arrow,
.tooltip{
  position: absolute;
}


.ql-toolbar {
  border-radius: 0.375rem 0.375rem 0 0 !important;
  border: 1px solid #ced4da !important;
}
.ql-container.ql-snow {
  border-radius: 0 0 0.375rem 0.375rem !important;
  border: 1px solid #ced4da !important;
  border-top: none !important;
}
.ql-editor {
  padding: 8px !important;
  p {
    font-size: 14px !important;
    font-weight: 300 !important;
  }
}
.ql-editor.ql-blank::before {
  color: #a7a8a9 !important;
  font-style: normal;
  left: 8px;
  font-size: 14px;
  font-weight: 300;
}

.wizard-container {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

  h3, h4 {
    font-size: 18px;
  }

  h1, h2, h3, h4 {
    text-align: left;
    color: #233668;
  }

  p, ul, li {
    color: #233668;
  }
}

